import { EventRepeat } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Chip, Grid, Tab, Typography } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { NavigationLink, UserDetailBlock } from '../../../../components'
import { PatientOrProvider } from '../../../../components/PatientOrProviderDetails/types'
import { usePeerGroups } from '../../../../providers/PeerGroups/PeerGroups.provider'
import { useProviderDetails } from '../../../../providers/ProviderDetails/ProviderDetails.provider'
import { useSchedule } from '../../../../providers/Schedule/Schedule.provider'
import {
  Appointment,
  AppointmentType,
  Participant,
  Patient,
} from '../../../../providers/Schedule/types'
import { useVrHeadset } from '../../../../providers/VrHeadset/VrHeadset.provider'
import { themeColors } from '../../../../theme'
import { dateFormatters } from '../../../../utilities/HelperFunctions'
import { JoinVRHelpModal } from './JoinVRHelpModal'
import { JoinVRModal } from './JoinVRModal'

interface ScheduleDetailViewProps {
  appointment: Appointment
  returnToListView: () => void
  setShowCancelModalMode: Dispatch<SetStateAction<boolean>>
  setShowRemovePatientModalMode: Dispatch<SetStateAction<boolean>>
  selectPatient: (patient: Patient) => void
  removingPatient: boolean
  addPatientModalOpen: boolean
  setAddPatientModalOpen: Dispatch<SetStateAction<boolean>>
}

export const ScheduleDetailView = ({
  appointment,
  returnToListView,
  setShowCancelModalMode,
  setShowRemovePatientModalMode,
  selectPatient,
  setAddPatientModalOpen,
}: ScheduleDetailViewProps) => {
  const go = useNavigate()
  const { isPaired } = useVrHeadset()
  const { joinVRSession } = usePeerGroups()
  const [tabState, setTabState] = useState<string>('patients')
  const [joinModalOpen, setJoinModalOpen] = useState<boolean>(false)
  const [joinHelpModalOpen, setJoinHelpModalOpen] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const { scheduleMode } = useSchedule()
  const { myProviderDetails } = useProviderDetails()

  useEffect(() => {
    const { participants, observers } = appointment

    const participantIds = participants.map(
      (participant) => participant.participantId
    )

    const observerIds =
      observers?.map((observer) => observer.participantId) || []

    const myProviderId = myProviderDetails?.providerId

    if (myProviderDetails?.accessRoles) {
      setIsDisabled(
        !(
          myProviderDetails?.accessRoles[0].description === 'SuperAdmin' ||
          myProviderDetails?.accessRoles[0].description === 'Admin' ||
          (myProviderId !== undefined && participantIds.includes(myProviderId))
        )
      )
    }
  }, [scheduleMode])

  const handleChange = (_: any, newValue: string) => {
    setTabState(newValue)
  }

  const sessionNotPassed = new Date(appointment.startTime) >= new Date() // If appointment.startTime >= current date, it means the session has not passed

  return (
    <Box sx={{ height: 'inherit' }}>
      {joinModalOpen && (
        <JoinVRModal
          showJoinModal={joinModalOpen}
          setShowJoinModal={setJoinModalOpen}
          handleHelpNavigation={() => {
            setJoinHelpModalOpen(true)
            setJoinModalOpen(false)
          }}
        />
      )}
      {joinHelpModalOpen && (
        <JoinVRHelpModal
          showJoinHelpModal={joinHelpModalOpen}
          setShowJoinHelpModal={setJoinHelpModalOpen}
        />
      )}

      <Grid
        container
        sx={{ height: '100%', overflow: 'auto', position: 'relative' }}
      >
        <Grid item xs={7} sx={{ padding: '5px 0', maxHeight: 'inherit' }}>
          <NavigationLink
            label='Back to schedule'
            direction='backward'
            handleClick={returnToListView}
          />
          <Box sx={{ height: 'max-content' }}>
            <Box sx={{ height: 'max-content' }}>
              <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Typography
                  sx={{
                    color: themeColors.grayblack,
                    fontSize: '15px',
                    fontWeight: '700',
                  }}
                >
                  {appointment.appointmentName}
                </Typography>
                <Typography
                  sx={{
                    color: themeColors.grayblack,
                    fontSize: '13px',
                    fontWeight: '400',
                  }}
                >
                  {dateFormatters.prettyDate(appointment.startTime)}
                </Typography>
                <Box sx={{ display: 'flex', color: themeColors.gray40 }}>
                  {appointment.isRecurring && (
                    <EventRepeat
                      fontSize='small'
                      sx={{ paddingRight: '5px' }}
                    />
                  )}
                  <Typography
                    sx={{
                      color: themeColors.gray40,
                      fontSize: '13px',
                      fontWeight: '400',
                    }}
                  >
                    {dateFormatters.time(appointment.startTime)} -{' '}
                    {dateFormatters.time(appointment.endTime)}
                  </Typography>
                </Box>
                {appointment.isVr && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Chip
                      label={
                        appointment.participantCount -
                        appointment.patients.length
                          ? 'Available'
                          : 'Full'
                      }
                      size='small'
                      sx={{ marginRight: '5px', padding: '1px' }}
                    />
                    <Typography
                      sx={{
                        color: themeColors.gray40,
                        fontSize: '13px',
                        fontWeight: '400',
                      }}
                    >
                      {`${appointment.patients.length}/${appointment.seatsAvailable} Patient Seats Filled`}
                    </Typography>
                  </Box>
                )}
              </Box>

              {appointment.isVr ? (
                <Box>
                  <TabContext value={tabState}>
                    <TabList
                      onChange={handleChange}
                      aria-label='Details Tabs'
                      sx={{
                        width: '70%',
                        padding: 0,
                        margin: '10px 0 0 20px',
                      }}
                      // variant='fullWidth'
                      indicatorColor={'secondary'}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: themeColors.blue,
                          height: '5px',
                          borderRadius: '6px',
                          padding: 0,
                        },
                      }}
                    >
                      <Tab
                        label={`Moderators (${appointment.participants.length})`}
                        value={'moderators'}
                        style={{
                          marginRight: '10px',
                          padding: '0',
                        }}
                      />
                      <Tab
                        label={`Patients (${appointment.patients.length})`}
                        value={'patients'}
                        disabled={!appointment.patients?.length || isDisabled}
                        style={{ marginRight: '10px', padding: '0' }}
                      />
                      <Tab
                        label={`Observers (${appointment.observers?.length})`}
                        value={'observers'}
                        disabled={!appointment.observers?.length}
                        style={{ marginRight: '10px', padding: '0' }}
                      />
                    </TabList>
                    <TabPanel
                      value={'patients'}
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {!isDisabled &&
                        appointment.patients.map(
                          (participant: Patient, i: number) => (
                            <UserDetailBlock
                              key={participant.name + i}
                              user={{
                                name: participant.name,
                                photoUrl: participant.photoURL,
                                patientMrn: participant.mrn,
                                patientOrProvider: PatientOrProvider.Patient,
                              }}
                              onUserClick={() => {
                                setShowRemovePatientModalMode(true)
                                selectPatient(participant)
                              }}
                              showRemovePatient={sessionNotPassed}
                            />
                          )
                        )}
                    </TabPanel>

                    <TabPanel
                      value={'moderators'}
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {appointment.participants.map(
                        (participant: Participant, i: number) => (
                          <UserDetailBlock
                            key={participant.participantName + i}
                            {...{
                              user: {
                                name: participant.participantName,
                                photoUrl: participant.participantPhotoURL,
                                providerRole: participant.participantRole,
                                patientMrn: participant.participantRole,
                                patientOrProvider: PatientOrProvider.Provider,
                              },
                            }}
                          />
                        )
                      )}
                    </TabPanel>
                    <TabPanel
                      value={'observers'}
                      sx={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {appointment.observers?.map(
                        (participant: Participant, i: number) => (
                          <UserDetailBlock
                            key={participant.participantName + i}
                            {...{
                              user: {
                                name: participant.participantName,
                                photoUrl: participant.participantPhotoURL,
                                providerRole: participant.participantRole,
                                patientMrn: participant.participantRole,
                                patientOrProvider: PatientOrProvider.Provider,
                              },
                            }}
                          />
                        )
                      )}
                    </TabPanel>
                  </TabContext>
                </Box>
              ) : (
                appointment.patients.map((participant: Patient, i: number) => (
                  <UserDetailBlock
                    showRemovePatient
                    key={participant.name + i}
                    {...{
                      user: {
                        name: participant.name,
                        photoUrl: participant.photoURL,
                        patientMrn: participant.mrn,
                        patientOrProvider: PatientOrProvider.Patient,
                      },
                    }}
                  />
                ))
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'sticky',
            top: 0,
            padding: '10px',
            alignSelf: 'flex-start',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              margin: 1,
              marginBottom: 1.5,
            }}
          >
            {appointment.isVr ? (
              <>
                <Button
                  disableRipple
                  variant='contained'
                  color='info'
                  sx={{ margin: '0px 10px', width: '500px' }}
                  onClick={() => setAddPatientModalOpen(true)}
                >
                  Add Patient to Peer Group
                </Button>

                {!isPaired ? (
                  <Button
                    disableRipple
                    variant='outlined'
                    color='error'
                    onClick={() => go('/account')}
                  >
                    Pair headset to join VR
                  </Button>
                ) : (
                  <Button
                    disableRipple
                    disabled={isDisabled}
                    variant='contained'
                    onClick={async () => {
                      await joinVRSession(appointment.appointmentId!)
                      setJoinModalOpen(true)
                    }}
                  >
                    Join {AppointmentType.VR.toString()}
                  </Button>
                )}
              </>
            ) : appointment.meetingURL ? (
              <Button
                disableRipple
                variant='contained'
                href={appointment.meetingURL}
                target='_blank'
              >
                Join {AppointmentType.Zoom.toString()}
              </Button>
            ) : (
              <Typography color='error' sx={{ fontSize: '14px' }}>
                No Zoom Link
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              minHeight: 180,
              borderRadius: 0.8,
              padding: 1.5,
              border: `2px solid ${themeColors.gray70}`,
            }}
          >
            <Typography
              sx={{
                color: themeColors.gray40,
                fontSize: '12px',
                fontWeight: '400',
              }}
            >
              {appointment.appointmentDescription}
            </Typography>
          </Box>

          {!appointment.isVr ? (
            <Typography
              sx={{
                color: themeColors.gray40,
                fontSize: '13px',
                fontWeight: '700',
                marginTop: '5px',
              }}
            >
              *Patient must consent before rescheduling.
            </Typography>
          ) : null}

          {appointment.isVr ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {myProviderDetails?.accessRoles &&
                (myProviderDetails?.accessRoles[0].description === 'Admin' ||
                  myProviderDetails?.accessRoles[0].description ===
                    'SuperAdmin') && (
                  <Box sx={{ margin: 1, marginTop: 1.5 }}>
                    <Button
                      variant='outlined'
                      color='error'
                      disabled={isDisabled}
                      onClick={() => setShowCancelModalMode(true)}
                    >
                      Cancel Session
                    </Button>
                  </Box>
                )}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Box sx={{ margin: 1, marginTop: 1 }}>
                <Button
                  variant='contained'
                  color='error'
                  href={appointment.cancelURL}
                  target='_blank'
                  onClick={returnToListView}
                >
                  Cancel
                </Button>
              </Box>
              <Box sx={{ margin: 1, marginTop: 1 }}>
                <Button
                  variant='outlined'
                  href={appointment.rescheduleURL}
                  target='_blank'
                >
                  Reschedule*
                </Button>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
